import QueryString from "qs"
import { useCallback, useMemo } from "react"
import { useLocation } from "react-router-dom"

export const useSearch = <T extends Record<string, string>>(
  path?: string,
  hash?: string
): { query: T; pathWithQuery: string; getPath: (path?: string, hash?: string) => string; pathname: string } => {
  const { search, pathname } = useLocation()

  const query = useMemo(() => QueryString.parse(search?.replace(/\?/, "")) as T, [search])

  const pathWithQuery = useMemo(() => {
    if (path != null) {
      return [path, search, hash != null ? `#${hash}` : undefined].filter(Boolean).join("")
    }

    return ""
  }, [search, path, hash])

  const getPath = useCallback(
    (path?: string, hash?: string) => {
      if (path != null) {
        return [path, search, hash != null ? `#${hash}` : undefined].filter(Boolean).join("")
      }

      return ""
    },
    [search]
  )

  return { query, pathWithQuery, getPath, pathname }
}
