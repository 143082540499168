import { Container } from "@/components/Container"
import { Icon } from "@/components/Icons"
import "./style.sass"
import { Tab } from "@/components/Tab"
import { ORDER_CARD_TABS } from "./tabsConfig"
import { Outlet, useNavigate, useSearchParams } from "react-router-dom"
import { ReactNode, Suspense, useMemo } from "react"
import { FormWrapper } from "@/components/Form/FormWrapper"
import { DEFAULT_VALUES, useSelectCardForm, useSelectCards } from "./Sections/SelectCards/useSelectCards"
import { OrderCardProvider } from "./OrderCardProvider"
import clsx from "clsx"

type Props = {
  isLanding?: boolean
  isWidget?: boolean
  children?: ReactNode
}

const OrderCard = ({ isLanding = false, children, isWidget = false }: Props) => {
  const navigate = useNavigate()
  const [x] = useSearchParams()

  const defaultValues = useMemo(() => {
    if (x.get("fromLanding")) {
      const values = Object.fromEntries(x.entries())

      return {
        ...values,
        cardType: JSON.parse(values.cardType),
        country: JSON.parse(values.country),
        currency: JSON.parse(values.currency),
        userCountry: JSON.parse(values.userCountry),
        phoneCode: decodeURIComponent(values.phoneCode),
        phoneNumber: decodeURIComponent(values.phoneNumber),
      }
    }

    const cardType = x.get("cardType") ? { label: x.get("cardType"), value: x.get("cardType") } : null

    if (x.get("s_id")) {
      return {
        ...DEFAULT_VALUES,
        cardType: cardType ?? null,
        code: x.get("s_id"),
        code2: x.get("s_id"),
      }
    }

    return {
      ...DEFAULT_VALUES,
      cardType: cardType ?? null,
    }
  }, [x])

  // @ts-expect-error
  const model = useSelectCardForm(defaultValues)

  const selectCardsProps = useSelectCards(model, !!x.get("fromLanding"), isWidget)

  return (
    <OrderCardProvider {...selectCardsProps}>
      <Container className="p-10">
        {!isLanding ? (
          <div className="order-card__header">
            <div
              className={clsx("order-card__header--title", !isWidget && "cursor-pointer")}
              onClick={() => {
                if (!isWidget) {
                  navigate("/")
                }
              }}
            >
              <h1>Virtual Prepaid Credit Card</h1>
            </div>
            <div className="order-card__header--info">
              <Icon icon="Alert" className="fill-black" />
              Your card will be delivered automatically after a successful payment
            </div>
          </div>
        ) : null}
        <FormWrapper
          model={model}
          onSubmit={() => {
            if (isLanding) {
              const values = model.getValues()

              navigate(
                `/dashboard/order-card/select?fromLanding=true&` +
                  [
                    `email=${values.email}`,
                    `firstName=${values.firstName}`,
                    `lastName=${values.lastName}`,
                    `state=${values.state}`,
                    `phoneCode=${encodeURIComponent(values.phoneCode)}`,
                    `phoneNumber=${encodeURIComponent(values.phoneNumber)}`,
                    `city=${values.city}`,
                    `address=${values.address}`,
                    `apartment=${values.apartment}`,
                    `zip=${values.zip}`,
                    `code=${values.code}`,
                    `denomination=${values.denomination}`,
                    `cardType=${JSON.stringify(values.cardType)}`,
                    `country=${JSON.stringify(values.country)}`,
                    `userCountry=${JSON.stringify(values.userCountry)}`,
                    `currency=${JSON.stringify(values.currency)}`,
                    `code=${x.get("s_id") ?? ""}`,
                    `code2=${x.get("s_id") ?? ""}`,
                  ].join("&")
              )
            } else {
              selectCardsProps.onRefresh(true)
            }
          }}
        >
          {children ?? (
            <main className="mt-16">
              <Tab items={ORDER_CARD_TABS} isCanNavigateByClick={false} />
              <Suspense fallback={<h1>Loading...</h1>}>
                <Outlet />
              </Suspense>
            </main>
          )}
        </FormWrapper>
      </Container>
    </OrderCardProvider>
  )
}

export default OrderCard
